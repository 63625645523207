import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './style5.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../assets/image/Jessica-Haines-Design-Logo.png';


function Header5({ handleSelect }) {
  return (
    <div className="fiveHeader">
      <div className="topnavTex_COURSES">
          <p> GET THE ALL-ACCESS-PASS AND SAVE A MASSIVE 40% ON ACCESS-PASS COURSES! </p>
          
        </div>
      <Container>
        <div className="text-center my-3">
          <img src={logo} style={{ width: '20%' }} alt="Company Logo" className='img-contain'/>
        </div>


        <Navbar collapseOnSelect expand="lg" sticky="top">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="m-auto">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#about-us">About Us</Nav.Link>
            
                <Nav.Link href="#blogs">Blogs</Nav.Link>
                <Nav.Link href="#contact">Contact</Nav.Link>
                <Nav.Link href="#blog1">Blog 1</Nav.Link>
               
               
            </Nav>
            {/* <div className="sign-up">
              <button onClick={handleSelect} className="btn btn-primary">
                Choose
              </button>
            </div> */}
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </div>
  );
}

export default Header5;
