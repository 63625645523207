import React from 'react';
import PropTypes from 'prop-types';
// import sub2Bg from '../assets/image/sub2bg.png' style={{ backgroundImage: `url(${sub2Bg})` }}

function Subscription2({ title1, title2, subTitle }) {  // Destructure props here
  return (
    <div>
      <section id="Subscrition2_main2" >
        <div className="container no-padding hidden">
          <div className="row">
            <div className="col-lg-12 align-items-center">
              <div className="Subscrition2_mainText">
                <div className="Subscrition2_title">
                  <h2>{title1}{title2}</h2>
                </div>
                <div className="Subscrition2_subTitle mb20">
                  <h4>{subTitle}</h4>
                </div>
                <div className="forms_main">
                  <form action="#">
                    <div className="subsription_Main2 main_groups d-flex gap20">
                      <div className="group_fields Subscrition2_Field">
                        
                        <input
                          type="text"
                          id="fname"
                          name="fname"
                          placeholder="Your Name"
                        />
                      </div>
                      <div className="group_fields Subscrition2_Field">
                       
                        <input
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Your Email"
                        />
                      </div>
                    </div>
                    <div className="main_groups">
                      <input
                        type="submit"
                        defaultValue="Submit"
                        className="submit_bttn btn_i"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

Subscription2.defaultProps = {
  title1: 'FINDING ',
  title2: 'MASTERY',
  subTitle: 'Stay up-to-date with the latest high performance and wellbeing podcasts and content with the Finding Mastery weekly newsletter.',
};

Subscription2.propTypes = {
  title1: PropTypes.string,
  title2: PropTypes.string,
  subTitle: PropTypes.string,
};

export default Subscription2;
