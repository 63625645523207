


import React from 'react';

import Slide5 from './Slide5';

function AboutPage5({ handleSelect }) {
  return (
    <div>
      <button className='rightseltedbtn btn_i' onClick={handleSelect}>Choose 5</button>
      <Slide5/>

    </div>
  );
}

export default AboutPage5;
