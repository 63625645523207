import React from "react";
import { Button } from "react-bootstrap"; 
import './style3.css';
import logo from '../assets/image/GREG+FAXON+LOGO+-+COLOR_LG.png';

const Header3 = ({  }) => {
  return (
    <header className="main_header">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="header_inner d-flex align-items-center gap10 justify-space-between">
              <div className="header_logo">
                <a href="#" aria-label="header-logo" onClick={(e) => e.preventDefault()}>
                  <img src={logo} alt="header logo" className="img-contain" />
                </a>
              </div>
              <div className="header_menu">
                <div className="humberg_menu">
                  <div className="humberg_icon d-none">
                    <i className="fa-solid fa-bars"></i>
                  </div>
                </div>
                <nav className="navbar">
                  <ul className="nav_menu list-none d-flex">
                    <div className="humberg_close d-none">
                      <i className="fa-solid fa-xmark"></i>
                    </div>
                    <li className="items_list">
                      <a href="" className="current_nav">Home</a>
                    </li>
                    <li className="items_list">
                      <a href="">About</a>
                    </li>
                    <li className="items_list have_child">
                      <a href="#">Community</a>
                      <ul className="dropdown-menu list-none">
                        <li className="items_list">
                          <a href="">Blogs</a>
                        </li>
                        <li className="items_list">
                          <a href="">Group</a>
                        </li>
                        <li className="items_list">
                          <a href="#">Forums</a>
                        </li>
                      </ul>
                    </li>
                    <li className="items_list">
                      <a href="./Contact.html">Contact</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="header_bttns align-items-center d-flex gap20">
                <div className="search-icon">
                 
                  <div className="header-search-wrap d-none">
                    <div className="container">
                      <form
                        role="search"
                        method="get"
                        className="search-form d-flex align-items-center"
                        action=""
                      >
                        <label>
                          <span className="screen-reader-text">Search for:</span>
                          <input
                            type="search"
                            className="search-field-top ui-autocomplete-input"
                            placeholder="Search..."
                            value=""
                            name="s"
                            autoComplete="off"
                          />
                        </label>
                        <input type="hidden" name="bp_search" value="1" />
                        <input type="hidden" name="view" value="content" />
                      </form>
                      <a href="#" className="close-search relative">
                        <i className="fa-solid fa-xmark"></i>
                      </a>
                    </div>
                  </div>
                </div>
                {/* <div className="sign_in">
                  <a href="#" className="sign-in">Sign In</a>
                </div> */}
                <div className="sign-up">
                  <a href="#" className="Work_sign-up btn_i">Work with me</a>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header3;
