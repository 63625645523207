import React from 'react';

function Footer3({ handleSelect }) {
  return (
    <div>
      <section className="footer_section_Ai mt20 pdb20">
        {/* Adjusted button with inline styles for testing */}
        <button
          
          className='rightseltedbtn btn_i'
          onClick={handleSelect}
        >
          Choose 3
        </button>
        <div className="container">
          <div className="row">
            <div className="footer article_box col-lg-4">
              {/* <div className="footer_box_title text-left">
                <h3 className="needHeding">Need Help?</h3>
              </div> */}
              <div className="Need_Help_text text-left">
                <p>
                  Contact us for any queries you have about GIofAI membership, trainings and mentorship programs.
                </p>
              </div>
            </div>
            <div className="footer article_box col-lg-2">
              <div className="footer_box_title">
                <h3>Call GIofAI</h3>
              </div>
              <div className="fotterCall_text">
                <h4>National Office</h4>
              </div>
              <div className="fotterCall_text pdb20">
                <h4>Phone: 1800 434 008</h4>
              </div>
              <div className="footer traning_image_link">
                <a href="#">
                  Contact <span><i className="fa-solid fa-arrow-right" /></span>
                </a>
              </div>
            </div>
            <div className="footer article_box col-lg-2">
              <div className="footer_box_title">
                <h3>Find An Answer</h3>
              </div>
              <div className="fotterCall_text">
                <h4>Have a question?</h4>
              </div>
              <div className="fotterCall_text pdb20">
                <h4>We can help.</h4>
              </div>
              <div className="footer traning_image_link">
                <a href="#">
                  View FAQ <span><i className="fa-solid fa-arrow-right" /></span>
                </a>
              </div>
            </div>
            <div className="footer article_box col-lg-2">
              <div className="footer_box_title">
                <h3>Give Us Feedback</h3>
              </div>
              <div className="fotterCall_text">
                <h4>We would love to know</h4>
              </div>
              <div className="fotterCall_text pdb20">
                <h4>your thoughts.</h4>
              </div>
              <div className="footer traning_image_link">
                <a href="#">
                  Provide feedback
                  <span><i className="fa-solid fa-arrow-right" /></span>
                </a>
              </div>
            </div>
          </div>
          <div className="last_footer">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="terms_service">
                    <div className="writerNmarFooter">
                      <h4>All Rights Reserved © 2024 - GIofAI</h4>
                    </div>
                    <div className="footer_nav">
                      <ul>
                        <li><a href="#"><i className="fa-solid fa-question" /> Terms of Service</a></li>
                        <li><a href="#"><i className="fa-solid fa-question" /> Privacy Policy</a></li>
                        <li><a href="#"><i className="fa-solid fa-question" /> FAQ</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="writerNmarFooter_Icon">
                    <a href="#"><i className="fa-brands fa-facebook" /></a>
                    <a href="#"><i className="fa-brands fa-instagram" /></a>
                    <a href="#"><i className="fa-brands fa-linkedin" /></a>
                    <a href="#"><i className="fa-brands fa-youtube" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer3;
