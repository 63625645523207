import React, { useState } from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import PropTypes from 'prop-types';
import img1 from '../assets/image/team-5.jpg';
import img2 from '../assets/image/team-4.jpg';
import img3 from '../assets/image/team-3.jpg';
import img4 from '../assets/image/team-2.jpg';
import img5 from '../assets/image/team-1.jpg';
import img6 from '../assets/image/team-3.jpg';

function Testimonial3({ title }) {
    const testimonials = [
        {
            photo: img1,
            name: "Anup Kumar",
            position: "Software Developer",
            location: "India",
            content: '“Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus magni tempore provident? Quaerat, dicta saepe praesentium eaque nobis corrupti aut, quibusdam provident consequatur.”',
            stars: 4
        },
        {
            photo: img2,
            name: "Aman Kumar",
            position: "Software Developer",
            location: "India",
            content: '“Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus magni tempore provident? Quaerat, dicta saepe praesentium eaque nobis corrupti aut, quibusdam provident consequatur.”',
            stars: 5
        },
        {
            photo: img3,
            name: "Sahil Kumar",
            position: "Software Developer",
            location: "India",
            content: '“Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus magni tempore provident? Quaerat, dicta saepe praesentium eaque nobis corrupti aut, quibusdam provident consequatur.”',
            stars: 4
        },
        {
            photo: img4,
            name: "Ravi Singh",
            position: "Software Engineer",
            location: "India",
            content: '“Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus magni tempore provident? Quaerat, dicta saepe praesentium eaque nobis corrupti aut, quibusdam provident consequatur.”',
            stars: 3
        },
        {
            photo: img5,
            name: "Kiran Verma",
            position: "Project Manager",
            location: "India",
            content: '“Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus magni tempore provident? Quaerat, dicta saepe praesentium eaque nobis corrupti aut, quibusdam provident consequatur.”',
            stars: 4
        },
        {
            photo: img6,
            name: "Rakesh Yadav",
            position: "Data Scientist",
            location: "India",
            content: '“Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus magni tempore provident? Quaerat, dicta saepe praesentium eaque nobis corrupti aut, quibusdam provident consequatur.”',
            stars: 5
        },
    ];

    const [activeIndex, setActiveIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setActiveIndex(selectedIndex);
    };

    const renderStars = (numStars) => {
        const fullStar = "★";
        const emptyStar = "☆";
        return (
            <div className="stars-rating">
                {fullStar.repeat(numStars) + emptyStar.repeat(5 - numStars)}
            </div>
        );
    };

    return (
        <section className="testimonial-section animate-up pdb50">
            <Container>
                <Row className="justify-content-center align-items-center position-relative">
                    <div className="testimonial-title text-center">
                        <h2>{title}</h2>
                    </div>
                    <Col lg={12} className="position-relative ">
                        <div className="testimonial_content_card3 pdb50">
                            <Carousel
                                activeIndex={activeIndex}
                                onSelect={handleSelect}
                                controls={false}    
                                indicators={false}  
                                interval={1000}     
                            >
                               
                                {testimonials.map((testimonial, i) => (
                                    <Carousel.Item key={i}>
                                        <Row className="imageTextTesti d-flex align-items-center">
                                            <Col lg={4} className=" d-flex justify-content-center">
                                                <div className="testimonial_item3">
                                                    <img
                                                        src={testimonial.photo}
                                                        alt={testimonial.name}
                                                        className="testimonial_image"
                                                    />
                                                   
                                                   
                                                </div>
                                            </Col>
                                            <Col lg={8}>
                                                <div className="testimonial_quote3">
                                                    
                                                    <p className="testimonial_author3">
                                                        {testimonial.content}
                                                    </p>
                                                    <p>{testimonial.name},</p>
                                                    <h5>{testimonial.position}, {testimonial.location},</h5>
                                                    <strong>{renderStars(testimonial.stars)}</strong>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

Testimonial3.defaultProps = {
    title: 'Testimonials',
};

Testimonial3.propTypes = {
    title: PropTypes.string,
};

export default Testimonial3;
