// import React from 'react'

//  function Footer2({handleSelect}) {
//   return (
//     <div>
//       <button className='rightseltedbtn btn_i' onClick={handleSelect}>Select Footer</button>
//       <h1>Footer 2</h1>
      
//     </div>
//   )
// }
// export default Footer2;
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa'; // Social icons

const Footer2 = ({handleSelect}) => {
  return (
    <footer className="footer-section">
      <button className='rightseltedbtn btn_i' onClick={handleSelect}>Choose 2</button>
      <Container>
        <Row className="py-5">
        
          <Col md={4} sm={12} className="mb-4">
            <h5>About Us</h5>
            <p>
              We are a leading tech company providing top-notch web and mobile solutions. Get in touch to learn more about our services and products.
            </p>
          </Col>

    
          <Col md={4} sm={12} className="mb-4">
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li><a href="#about">About Us</a></li>
              <li><a href="#services">Our Services</a></li>
              <li><a href="#careers">Careers</a></li>
              <li><a href="#contact">Contact Us</a></li>
            </ul>
          </Col>

          <Col md={4} sm={12}>
            <h5>Contact Us</h5>
            <p>1234 React Lane, Suite 5678</p>
            <p>City, State, 12345</p>
            <p>Email: support@company.com</p>
            <p>Phone: (123) 456-7890</p>

      
            <div className="social-icons">
              <a href="" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
              <a href="" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
              <a href="" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
              <a href="" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>
            </div>
          </Col>
        </Row>


        {/* <Row className="text-center pt-3 border-top">
          <Col>
            <p className="mb-0">© {new Date().getFullYear()} Your Company. All rights reserved.</p>
          </Col>
        </Row> */}
      </Container>
    </footer>
  );
};

export default Footer2;
