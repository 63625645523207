import React from 'react'
import img_M from '../assets/image/bg-presentation.jpg'

export default function Membership() {
  return (
    <div>
          <section className="MEMBERSHIP_section pdb20">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="MEMBERSHIP_page_text_main">
                  <div className="MEMBERSHIP_sub_title text-center">
                    <h5>MEMBERSHIP</h5>
                  </div>
                  <div className="Membership_i">
                    <div className="Membership_title text-center pdb20">
                      <h2>Stay ahead of what matters.</h2>
                    </div>
                    <div className="Membership_button text-center">
                      <a href="#" className="Membership btn_i text-center">Sign Up <span><i className="fa-solid fa-arrow-up" /></span></a>
                    </div>
                    <div className="Membership_video text-center"  style={{ backgroundImage: `url(${img_M})` }}>
                   
                    </div>
                    {/* <div className="Membership_text_p pdb20 text-center">
                      <p>
                        GIofAI membership is designed to guide and empower you with a
                        wealth of resources, so you can reach your full potential.
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div> 
          </div> 
        </section>
        <section className="membership Find_out_more_about">
          <div className="container">
            <div className="row ">
              <div className="membership_main_article d-flex gap20 pdb50 flexwrap" style={{justifyContent: 'center'}}>
                <div className="article_box">
                  <div className="membership about_file_box">
                    <div className="about_file_icon">
                      <a href="#"><i className="fa-solid fa-plus" /></a>
                    </div>
                    <div className="about_file_box_title">
                      <h4>BECOME MORE EFFECTIVE</h4>
                    </div>
                    <div className="about_file_box_text">
                      <p>
                        Access to rigorously curated tools and resources
                        specifically designed for the Data &amp; AI professional, backed
                        by world-class Data &amp; AI education.
                      </p>
                    </div>
                    <div className="about_file_box_link">
                      <a href="#">EXPLORE GIofAI<span><i className="fa-solid fa-arrow-right" /></span></a>
                    </div>
                  </div>
                </div>
                <div className="article_box">
                  <div className="membership about_file_box">
                    <div className="about_file_icon">
                      <a href="#"><i className="fa-solid fa-lightbulb" /></a>
                    </div>
                    <div className="about_file_box_title">
                      <h4>AI &amp; DATA LEADERSHIP</h4>
                    </div>
                    <div className="about_file_box_text">
                      <p>
                        We advocate for excellence in AI &amp; Data innovation and
                        realted governance to make a positive impact on society and
                        the economy.
                      </p>
                    </div>
                    <div className="about_file_box_link">
                      <a href="#">EXPLORE GIofAI<span><i className="fa-solid fa-arrow-right" /></span></a>
                    </div>
                  </div>
                </div>
                <div className>
                  <div className="membership about_file_box">
                    <div className="about_file_icon">
                      <a href="#"><i className="fa-solid fa-users" /></a>
                    </div>
                    <div className="about_file_box_title">
                      <h4>ACCESS A DIVERSE COMMUNITY</h4>
                    </div>
                    <div className="about_file_box_text">
                      <p>
                        Engage and learn within world’s largest director community,
                        attend insightful events and networking opportunities and
                        access our member-only business lounges.
                      </p>
                    </div>
                    <div className="about_file_box_link">
                      <a href>EXPLORE GIofAI<span><i className="fa-solid fa-arrow-right" /></span></a>
                    </div>
                  </div>
                </div>
                <div className="article_box">
                  <div className="membership about_file_box">
                    <div className="about_file_icon">
                      <a href="#"><i className="fa-solid fa-graduation-cap" /></a>
                    </div>
                    <div className="about_file_box_title">
                      <h4>BE RECOGNISED</h4>
                    </div>
                    <div className="about_file_box_text">
                      <p>
                        Membership and your commitment to ongoing professional
                        development signals to the wider professional community that
                        you take good governance seriously.
                      </p>
                    </div>
                    <div className="about_file_box_link">
                      <a href="#">EXPLORE GIofAI<span><i className="fa-solid fa-arrow-right" /></span></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      
    </div>
  )
}
