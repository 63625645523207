import React from 'react';
import PropTypes from 'prop-types';

// import imageSrc from '../image/image2.jpg';


function TabBar1({ Highlight_title, title1, title2, title3, text1, text2, text3, handleSelect }) {
  return (
    <div>


      <section className='main_banner_page Hightight_page animate-up'>
      <button className='rightseltedbtn btn_i' onClick={handleSelect}>Choose 1</button>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
                <div className='Highlight_title'>
                    <h2>{Highlight_title}</h2>

                </div>

            </div>
          <div className='col-md-4'>
              <div className='Highlight_banner'>
                <div className='number_count'><h2>1</h2></div>
              <div className='main_banner_image_title'>
                <h3>{title1}</h3>
              </div>
              <div className='main_banner_text'>
                <h5>{text1}</h5>
                <br/>
                <h5>{text2}</h5>
                <br/>
                <h5>{text3}</h5>
              </div>
              </div>
            </div>

            <div className='col-md-4'>
            <div className='Highlight_banner'>
            <div className='number_count'><h2>2</h2></div>
              <div className='main_banner_image_title'>
                <h3>{title2}</h3>
              </div>
              <div className='main_banner_text'>
                <h5>{text1}</h5>
                <br/>
                <h5>{text2}</h5>
                <br/>
                <h5>{text3}</h5>
              </div>
            </div>
            </div>
            <div className='col-md-4'>
            <div className='Highlight_banner'>
            <div className='number_count'><h2>3</h2></div>
              <div className='main_banner_image_title'>
                <h3>{title3}</h3>
              </div>
              <div className='main_banner_text'>
                <h5>{text1}</h5>
                <br/>
                <h5>{text2}</h5>
                <br/>
                <h5>{text3}</h5>
              </div>
            </div>
          </div>
          <div className='col-lg-12'>
          <div className='about-submit-button text-center pdb50'>
              <button type='button' className='btn btn-danger btn_i'>Learn More</button>
            </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  );
}


TabBar1.defaultProps = {
  Highlight_title: 'DURING THE MONEY CLARITY INTENSIVE, YOU',
  title1: 'Build a clear path to reach your goals',
  title2: 'Receive expert guidance and support',
  title3: 'Achieve results you never thought were possible',
  text1: 'Based on detailed sumbers you ll send me. Il mate Cantom Plan Ahrad Budgety.',
  text2: 'During out fiancial coaching calls, you ll learn to me a simplified budgeting system.',
  text3: 'Paying off debt, saving for what s important, and overcoming frars are just the beginning. ',
};


TabBar1.propTypes = {

  title: PropTypes.string,
  text: PropTypes.string,
};

export default TabBar1;
