import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import { FaSquareFacebook } from "react-icons/fa6";
import { FaWhatsappSquare } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaGoogle } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import logo from '../assets//image/footer4.png';

 function Footer4({handleSelect}) {
  return (
    <>
      <section className='footerFour4_Main pdb50'>
        <div className='container'>   
        <div className="row">
      {/* <button className='rightseltedbtn btn_i' onClick={handleSelect}>Choose 4</button>
   */}
        <div className='col-lg-8'>
        <a className="footerLogo4 " href="#">
          <img src={logo} alt="" className='img-contain footer_logoFour'/>
        </a>
        </div>

     
        <div className='col-lg-4'>
        <div className="footer_four">
        <div className='footer_navbar'>
             
              <p>
                <a href='#!' className='text-reset'>
                  Home
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  About
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Blogs
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Contact
                </a>
              </p>
              
            </div>
            <div className='Footersocial_icon'>
          <a href='' className='me-4 text-reset'>
          <FaSquareFacebook />
          </a>
          <a href='' className='me-4 text-reset'>
            <FaWhatsappSquare/>
          </a>
          <a href='' className='me-4 text-reset'>
            <FaSquareInstagram/>
          </a>
          <a href='' className='me-4 text-reset'>
        <FaSquareXTwitter/>
          </a>
          <a href='' className='me-4 text-reset'>
         <FaGoogle/>
          </a>
          <a href='' className='me-4 text-reset'>
            <FaLinkedin/>
         
          </a>
          {/* <a href='' className='me-4 text-reset'>
            <FaGithub/>
         
          </a> */}
        </div>
        </div>
 </div>
    </div>
    </div>
    </section>
   
    </>
  );
}

export default Footer4;