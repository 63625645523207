import React from 'react';

import   './style3.css';
import Slideimage from './Slideimage';



function AboutPage3({ handleSelect }) {
  return (
    <div>
    <Slideimage/>
    
      </div>
  
  
  );
}

export default AboutPage3;

