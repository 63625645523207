import React from 'react';
import SubImg5 from '../assets/image/subscription5.png';
import PropTypes from 'prop-types';

function Subscription5({ title, titleside, subtitleside, subtitleS, kit, noticTitle }) { // Destructure props here
  return (
    <div>
      <section id="get_touch_m pdb50">
        <div className="container no-padding hidden">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="subsCribeMain "
                
              >
                {/* style={{ backgroundImage: `url(${contactBG})` }} */}

                <div className="subImg">
                  <img src={SubImg5} alt="" className='img-contain' />
                </div>
                <div className="subImg_title ">
                  <p>{subtitleside} <span>{subtitleS}</span> <strong>{kit}</strong></p>

                </div>
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center">
              <div className="Subscritipn_main">
                <div className="Subscritipn_title ">
                  <h2>{title}</h2>
                </div>
                <div className="Subscritipn_subTitle ">
                  <h4>{titleside}</h4>
                </div>
                <div className="Subscritipn_input">
                  <form action="#">
                    <div className="Subscritipn_main_groups">
                      <div className="group_fields">
                        <label htmlFor="Youremail">
                          <span className="visually-hidden">Your Email</span>
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Email Address"
                        />
                      </div>
                   
                    <div className="main_groups">
                      <a
                        type="submit"
                        defaultValue="Submit"
                        className="SubsSubmit btn_i"
                      >Submit</a>
                    </div>
                    <div className="noticeText">
                      <span>{noticTitle}</span>
                    </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

Subscription5.defaultProps = {
  title: 'Get The Simple & Spacious Deep Dive Kit Sent Straight To Your Inbox',
  titleside: 'A three-part resource to walk you through exploring what a simple and spacious business actually looks like for you and how you can make it your reality too.',
  subtitleside: 'THE SIMPLE & SPACIOUS DEEP DIVE  ',
  subtitleS:"BY JEN : ",
  kit:'KIT',
  noticTitle: 'Your information is 100% secure and will never be shared with anyone. You can unsubscribe at any time',
};



Subscription5.propTypes = {
  title: PropTypes.string,
  titleside: PropTypes.string,
  subtitleside: PropTypes.string,
  noticTitle: PropTypes.string,
};

export default Subscription5;
