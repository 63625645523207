// src/components/Footer.js
import React from 'react';
import logo from '../assets/image/wmi_logo.png';
import { FaSquareFacebook } from "react-icons/fa6";
import { FaWhatsappSquare } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaSquareXTwitter } from "react-icons/fa6";


const Footer = ({handleSelect}) => {
  
  return (
    <>
     <footer id="main_footer" >
    <div className="main_footer_upper">
    <button className='rightseltedbtn btn_i' onClick={handleSelect}>
      Select Footer 5
    </button>

      
      <div className="container">
        <div className="row">
         
            <div className='col-lg-4'>
                <div className="main_links_b">
                  <div className="link_heading">
                   <div className="footer_logo5">
                   <img src={logo} style={{ width: '48%' }} alt="Company Logo" className='img-contain'/>
                   </div>
                  </div>
               
                </div>
                </div>
                <div className='col-lg-4'>
                <div className="main_links_b">
                  <div className="link_heading_nav">
                  <div className='footer_navbar_one'>
                  <a href='#!' className='text-reset5'>
                   Home
                </a>
                <a href='#!' className='text-reset5'>
                   About  
                </a>
                <a href='#!' className='text-reset5'>
                  Blogs
                   </a>
                   <a href='#!' className='text-reset5'>
                   Contact
                </a><a href='#!' className='text-reset5'>
                   Term & Conditions |  
                </a>
                 <a href='#!' className='text-reset5'>
                   Privacy & Policy
                </a>
                
        
           
             
             
              
            </div>
                  
                  </div>
              
                </div>
                </div>
                
                <div className='col-lg-4'>
                <div className="main_links_b">
                  <div className="link_heading text-center">
                  <div className='Footersocial_icon'>
                      <a href='' className='me-4 text-reset'>
                      <FaSquareFacebook />
                      </a>
                      <a href='' className='me-4 text-reset'>
                        <FaWhatsappSquare/>
                      </a>
                      <a href='' className='me-4 text-reset'>
                        <FaSquareInstagram/>
                      </a>
                      <a href='' className='me-4 text-reset'>
                    <FaSquareXTwitter/>
                      </a>
               </div>
                  </div>
                 
                 
                </div>
              </div>
              </div>
            
      
        </div>
      </div>
   
    <div className="copyright_text">
    
    </div>
  </footer> 
    </>
  );
};

export default Footer;
