// import React from 'react'

// const Subscription4 = () => {
//   return (
//     <div>
//       <h1>Subscription4</h1>
//     </div>
//   )
// }

// export default Subscription4

import React from 'react';
import SubImg5 from '../assets/image/subscription2.png';
import PropTypes from 'prop-types';
import { FaAngleRight } from "react-icons/fa";

function Subscription4({ title, titleside, subtitleside, subtitleS, kit, noticTitle }) { // Destructure props here
  return (
    <div>
      <section id="get_touch_m pdb50">
        <div className="container no-padding hidden">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="subsCribeMain4"
                
              >
                {/* style={{ backgroundImage: `url(${contactBG})` }} */}

                <div className="subImg">
                  <img src={SubImg5} alt="" className='img-contain' />
                </div>
                {/* <div className="subImg_title ">
                  <p>{subtitleside} <span>{subtitleS}</span> <strong>{kit}</strong></p>

                </div> */}
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center">
              <div className="Subscritipn_main">
                <div className="Subscritipn_title2 ">
                  <h2>{title}</h2>
                </div>
                <div className="Subscritipn_subTitle2 ">
                  <h4>{titleside}</h4>
                </div>
                <div className="Subscritipn_input">
                  <form action="#">
                    <div className="Subscritipn_main_groups">
                      <div className="group_fields subButton4">
                        <label htmlFor="Youremail">
                          <span className="visually-hidden">Your Email</span>
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Enter Email"
                        />
                        <a
                        type="submit"
                        defaultValue="Submit"
                        className="SubsSubmit2 "
                      >Subscription <FaAngleRight /></a>
                      </div>
                   
                
                   
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

Subscription4.defaultProps = {
  title: 'Free Consultations',
  titleside: 'Are you considering building a new site to grow your brand? Would you like to receive a free orientation on the options available? Our team of experts are available and happy to help your business grow!.',
}



Subscription4.propTypes = {
  title: PropTypes.string,
  titleside: PropTypes.string,
  
};

export default Subscription4;

