import React from 'react';


import PropTypes from 'prop-types';


function AboutPage1({ handleSelect }) {
  return (
    <div>
      
      <button className='rightseltedbtn btn_i' onClick={handleSelect}>Choose 1</button>

      <section className='about-coaching pdb50 animate-up'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='main-about-title text-center pdb50 '>
                <h1>Coaching and Advising for <br/> Growth Teams</h1>
              </div>
              <div className='main-about-text text-center'>
                <h4>Learn factors, playbooks, and leadership strategies to deliver <br/> more value and grow your career in tech.</h4>
              </div>
              <div className='about-submit-button text-center pdb50'>
                <button type='button' className='Growth_btn btn_i'>Learn More</button>
              </div>
              <div className='about-url text-center'>
                <a href='#'>Or subscribe to our newsletter for updates</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    
    </div>
  );
}

AboutPage1.propTypes = {
  handleSelect: PropTypes.func.isRequired,
};

export default AboutPage1;
