import React, { useState } from 'react';
import { Button, Navbar as BootstrapNavbar, Nav } from 'react-bootstrap';
import {  FaInstagram, FaLinkedin, FaYoutube, FaUser, FaHeart } from 'react-icons/fa';
import { MdOutlineMarkEmailUnread } from "react-icons/md"; // Import social media icons
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../assets/image/logo.png';
import './style1.css';
import { TiShoppingCart } from "react-icons/ti";


const Header = ({ handleSelect }) => {

  const [showRemove, setShowRemove] = useState(true);

  const handleHomeClick = (e) => {
    e.preventDefault();  
    setShowRemove(false);  
  };


  
  

  return (
    <>
    <div className="header">
      <div className='socioalIconFirst'>
      <div className=" container">
      <div className='row'>
        <div className='col-lg-6'>
        <div className="social-icons ">
            <a href=""  target="_blank" rel="noopener noreferrer" className="me-3">
            <MdOutlineMarkEmailUnread /> <span>wmiwebmedi@gmail.com</span>
             
            </a>
          
            
          </div>


        </div>
        
        <div className='col-lg-6'>
          <div className='social-iconsR'>
           
           <a href="" target="_blank" rel="noopener noreferrer" className="me-3">
            <FaUser />
            </a>
            <a href="" target="_blank" rel="noopener noreferrer" className="me-3">
              <FaInstagram />
            </a>
            <a href="" target="_blank" rel="noopener noreferrer" className="me-3">
              <FaLinkedin />
            </a>
            <a href="" target="_blank" rel="noopener noreferrer" className="me-3">
              <FaYoutube />
            </a>
            <a href="" target="_blank" rel="noopener noreferrer" className="me-3">
            <TiShoppingCart />
            </a>
           

          </div>
        

        </div>

      </div>

      </div>
      </div>
   
    
      <div className='hederOne'>
        <BootstrapNavbar expand="lg" className="navbarOne ">
          <div className="container">
          {showRemove && (
        <BootstrapNavbar.Brand href="#">
          <img src={logo} alt="Logo" className="navbar-logo" />
        </BootstrapNavbar.Brand>
      )}
        
           
            <BootstrapNavbar.Toggle aria-controls="navbarNav" />
            <BootstrapNavbar.Collapse id="navbarNav">
              <Nav className="ms-auto">
              <Nav.Link onClick={handleHomeClick} href="#">Home</Nav.Link>
                                                              
                                                            
                <Nav.Link href="#">About-us</Nav.Link>
                <Nav.Link href="#">Contact</Nav.Link>
                <Nav.Link href="#">Blogs</Nav.Link>
                <Nav.Link href="#">Career</Nav.Link>
                 </Nav>
                 <div className="sign-up">
                  <a href="#" className="buttonsign_up btn_i">Book A call</a>
                </div>
            </BootstrapNavbar.Collapse>
          </div>
        </BootstrapNavbar>
      </div>
      <div className='navbar_last'>

      </div>
      </div>
      </>

  );
};

export default Header;
