


import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import { FaSquareFacebook } from "react-icons/fa6";
import { FaWhatsappSquare } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaGoogle } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

import logo from '../assets//image/footerOne1.png';

 function Footer1({handleSelect}) {
  return (
    <>
      <section className='footerFour1_Main pdb50'>
        <div className='container'>   
        <div className="row">
      {/* <button className='rightseltedbtn btn_i' onClick={handleSelect}>Choose 4</button>
   */}
        <div className='col-lg-6'>
        <a className="footerLogo1 " href="#">
          <img src={logo} alt="" className='img-contain footer_logoFour'/>
        </a>
        </div>

     
        <div className='col-lg-6'>
        <div className="footer_One">
        <div className='Footersocial_icon'>
          <a href='' className='me-4 text-reset'>
          <FaSquareFacebook />
          </a>
          <a href='' className='me-4 text-reset'>
            <FaWhatsappSquare/>
          </a>
          <a href='' className='me-4 text-reset'>
            <FaSquareInstagram/>
          </a>
          <a href='' className='me-4 text-reset'>
        <FaSquareXTwitter/>
          </a>
          <a href='' className='me-4 text-reset'>
         <FaGoogle/>
          </a>
          <a href='' className='me-4 text-reset'>
            <FaLinkedin/>
         
          </a>
          {/* <a href='' className='me-4 text-reset'>
            <FaGithub/>
         
          </a> */}
        </div>
        <div className='footer_navbar_one'>
           
         
           <p className="mb-0">© {new Date().getFullYear()} Company Name.<a href='https://wmi.webmediaserver.com/' >
           webmediaserver
                </a>  All rights reserved.  |  
                <a href='#!' className='text-reset'>
                   Term & Conditions |  
                </a>

                <a href='#!' className='text-reset'>
                   Privacy & Policy
                </a>
                
                 </p>
           
             
             
              
            </div>
           
        </div>
 </div>
    </div>
    </div>
    </section>
   
    </>
  );
}

export default Footer1;
