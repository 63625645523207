import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import PropTypes from 'prop-types';
import img1 from '../assets/image/team-5.jpg';
import img2 from '../assets/image/team-4.jpg';
import img3 from '../assets/image/team-3.jpg';
import img4 from '../assets/image/team-2.jpg';
import img5 from '../assets/image/team-1.jpg';
import img6 from '../assets/image/team-3.jpg';
import './page2.css';

function ClientTestimonial({ title }) {
    const testimonials = [
        {
            photo: img1,
            name: "Anup Kumar",
            position: "Software Developer",
            location: "India",
            content: '“If it weren t for Mom Goes On, I would still be stuck in the same grief patterns. It s the catalyst to help you move from staying stuck in grief to living a much fuller life..”',
            stars: 5
        
        },
        {
            photo: img2,
            name: "Aman Kumar",
            position: "Software Developer",
            location: "India",
            content: '“Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus magni tempore provident? Quaerat, dicta saepe praesentium eaque nobis corrupti aut, quibusdam provident consequatur.”',
            stars: 4
        },
        {
            photo: img3,
            name: "Sahil Kumar",
            position: "Software Developer",
            location: "India",
            content: '“If it weren t for Mom Goes On, I would still be stuck in the same grief patterns. It s the catalyst to help you move from staying stuck in grief to living a much fuller life.”',
            stars: 3
        },
        {
            photo: img4,
            name: "Ravi Singh",
            position: "Software Engineer",
            location: "India",
            content: '“Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus magni tempore provident? Quaerat, dicta saepe praesentium eaque nobis corrupti aut, quibusdam provident consequatur.”',
            stars: 5
        },
        {
            photo: img5,
            name: "Kiran Verma",
            position: "Project Manager",
            location: "India",
            content: '“If it werent for Mom Goes On, I would still be stuck in the same grief patterns. Its the catalyst to help you move from staying stuck in grief to living a much fuller life.”',
            stars: 4
        },
        {
            photo: img6,
            name: "Rakesh Yadav",
            position: "Data Scientist",
            location: "India",
            content: '“If it werent for Mom Goes On, I would still be stuck in the same grief patterns. Its the catalyst to help you move from staying stuck in grief to living a much fuller life.”',
            stars: 3
        },
    ];

    const [activeIndex, setActiveIndex] = useState(0);
    const [itemsPerSlide, setItemsPerSlide] = useState(2); 

    useEffect(() => {
        const updateItemsPerSlide = () => {
            if (window.innerWidth < 576) {
                setItemsPerSlide(1);  
            } else {
                setItemsPerSlide(2);  
            }
        };

        updateItemsPerSlide(); 
        window.addEventListener('resize', updateItemsPerSlide);

        return () => window.removeEventListener('resize', updateItemsPerSlide);
    }, []);

    const handleSelect = (selectedIndex) => {
        setActiveIndex(selectedIndex);
    };

    const renderStars = (numStars) => {
        const fullStar = "★";
        const emptyStar = "☆";
        return (
            <div className="stars-rating">
                {fullStar.repeat(numStars) + emptyStar.repeat(5 - numStars)}
            </div>
        );
    };


    return (
        <section className="testimonial-section animate-up pdb50">
            <Container>
                <Row className="justify-content-center align-items-center position-relative">
                    <div className="testimonial-title text-center">
                        <h2>{title}</h2>
                    </div>
                    <Col lg={12} className="position-relative">
                        <div className="testimonial-content-card onsideTesim">
                            <Carousel
                                activeIndex={activeIndex}
                                onSelect={handleSelect}
                                controls={false}
                                indicators={false}
                                interval={1000}
                            >
                                {testimonials.map((_, i, arr) => 
                                    i % itemsPerSlide === 0 && (
                                        <Carousel.Item key={i}>
                                            <Row>
                                                {arr.slice(i, i + itemsPerSlide).map((testimonial, index) => (
                                                    <Col lg={6} xs={12} key={index}>
                                                        <div className="testimonial_item1 onsideTesim">
                                                            <img
                                                                src={testimonial.photo}
                                                                alt={testimonial.name}
                                                                className="testimonial-photo rounded-circle"
                                                            />
                                                            <div className="testimonial_quote">
                                                                <div className="testiFirstText">
                                                                    <p>{testimonial.content}</p>
                                                                </div>
                                                                <div className="testFirstName">
                                                                    <h5>{testimonial.name}</h5>
                                                                </div>
                                                                {renderStars(testimonial.stars)}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Carousel.Item>
                                    )
                                )}
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

ClientTestimonial.defaultProps = {
    title: 'Clients Testimonials',
};

ClientTestimonial.propTypes = {
    title: PropTypes.string,
};

export default ClientTestimonial;
