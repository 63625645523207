// import React, { useState } from 'react';
// import PropTypes from 'prop-types';
// import imageSrc1 from '../assets/image/team-3.jpg'; // Ensure path is correct
// import imageSrc2 from '../assets/image/team-2.jpg'; // Ensure path is correct
// import imageSrc3 from '../assets/image/team-1.jpg'; // Ensure path is correct

// function Banner2({ handleSelect }) { // Destructure handleSelect from props
//   // State for managing active tab
//   const [activeTab, setActiveTab] = useState(0);

//   // Image and text content for each tab
//   const tabContent = [
//     {
//       image: imageSrc1,
//       title: 'Tackle projects with more ease and faster execution',
//       text1: 'Hey there I\'m Grace, a passionate creative...',
//       text2: 'Consudaning what you hate about procrastination...',
//       text3: 'Through a process and outcome-driven coaching...',
//     },
//     {
//       image: imageSrc2,
//       title: 'Achieve your goals with structured productivity',
//       text1: 'Harness the power of structured planning...',
//       text2: 'Eliminate distractions and focus on key priorities...',
//       text3: 'Tailored roadmaps guide you to faster achievements...',
//     },
//     {
//       image: imageSrc3,
//       title: 'Boost your confidence and creativity',
//       text1: 'Step into a new realm of creativity and innovation...',
//       text2: 'Collaborate effectively and unleash your potential...',
//       text3: 'Develop tools that enhance output and satisfaction...',
//     },
//   ];

//   const currentContent = tabContent[activeTab];

//   return (
//     <div className="banner-container">
//       <section className="main-banner-page">
//         <button className='rightseltedbtn btn_i' onClick={handleSelect}>
//           Select banner 2
//         </button>

//         <div className="container-fluid">
//           {/* Tabs on top */}
//           <div className="tab-navigation">
//             <ul className="nav nav-tabs justify-content-center">
//               <li className="nav-item">
//                 <button
//                   className={`btn nav-link btn ${activeTab === 0 ? 'active' : ''}`}
//                   onClick={() => setActiveTab(0)}
//                 >
//                   Tackle
//                 </button>
//               </li>
//               <li className="nav-item">
//                 <button
//                   className={`btn nav-link ${activeTab === 1 ? 'active' : ''}`}
//                   onClick={() => setActiveTab(1)}
//                 >
//                   Harnes
//                 </button>
//               </li>
//               <li className="nav-item">
//                 <button
//                   className={`btn nav-link ${activeTab === 2 ? 'active' : ''}`}
//                   onClick={() => setActiveTab(2)}
//                 >
//                   Tailored
//                 </button>
//               </li>
//             </ul>
//           </div>

//           <div className="row align-items-center">
//             {/* Left side Image */}
//             <div className="col-md-6">
//               <div className="main-banner-image">
//                 <img src={currentContent.image} alt="Banner" className="img-fluid rounded" />
//               </div>
//             </div>

//             {/* Right side Text */}
//             <div className="col-md-6">
//               <div className="main-banner-text">
//                 <h2>{currentContent.title}</h2>
//                 <p>{currentContent.text1}</p>
//                 <p>{currentContent.text2}</p>
//                 <p>{currentContent.text3}</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// }

// Banner2.propTypes = {
//   handleSelect: PropTypes.func.isRequired, // Ensure handleSelect is required and of type function
// };

// export default Banner2;


import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

import bannerImage from '../assets/image/imgback.webp'; // Replace with your image path

const Banner2 = ({}) => {
  return (
    <div className="banner-section">
       
      <Container>
        <Row className="align-items-center justify-content-center">
         
          <Col md={6} className="banner-image-col p-0">
            <img src={bannerImage} alt="Banner" className="img-contain" />
          </Col>

          
          <Col md={6} className="banner-text-col d-flex flex-column justify-content-center">
            <div className="text-center text-md-start p-5">
              <h1 className="banner-title">Innovative Solutions for Your Business</h1>
              <p className="banner-description">
                We provide creative technology solutions to help take your business to the next level. Let's collaborate to create something extraordinary.
              </p>
              <Button variant="primary" className="mt-3 btn_i">Get Started</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Banner2;

