


import './App.css';
import '../src/Main.css';
import { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

// Header page imports
import Header from './components1/Header';
import Header2 from './components2/Header2';
import Header3 from './components3/Header3';
import Header4 from './components4/Header4';
import Header5 from './components5/Header5';

// About Page imports
import AboutPage1 from './components1/AboutPage1';
import AboutPage2 from './components2/AboutPage2';
import AboutPage3 from './components3/AboutPage3';
import AboutPage4 from './components4/AboutPage4';
import AboutPage5 from './components5/AboutPage5';

// Tab Bar imports
import TabBar1 from './components1/TabBar1';
import TabBar2 from './components2/TabBar2';
import TabBar3 from './components3/TabBar3';
import TabBar4 from './components4/TabBar4';
import TabBar5 from './components5/TabBar5';

// Banner imports
import Banner1 from './components1/Banner1';
import Banner2 from './components2/Banner2';
import Banner3 from './components3/Banner3';
import Banner4 from './components4/Banner4';
import Banner5 from './components5/Banner5';

// Testimonial2  imports

import Testimonial1 from './components1/Testimonial1';
import Testimonial2 from './components2/Testimonial2';
import Testimonial3 from './components3/Testimonial3';
import Testimonial4 from './components4/Testimonial4';
import Testimonial5 from './components5/Testimonial5';

// Subscription imports
import Subscription1 from './components1/Subscription1';
import Subscription2 from './components2/Subscription2';
import Subscription3 from './components3/Subscription3';
import Subscription4 from './components4/Subscription4';
import Subscription5 from './components5/Subscription5';

// Contact Page imports
// import Contact1 from './components1/Contact1';
// import Contact2 from './components2/Contact2';
// import Contact3 from './components3/Contact3';
// import Contact4 from './components4/Contact4';
// import Contact5 from './components5/Contact5';

// Footer Page imports
import Footer1 from './components1/Footer1';
import Footer2 from './components2/Footer2';
import Footer3 from './components3/Footer3';
import Footer4 from './components4/Footer4';
import Footer5 from './components5/Footer5';



// all components popup model

const SelectionModal = ({ show, handleClose, title, components, handleSelect, selectedComponent }) => {
  const sortedComponents = [
    components.find(c => c.name === selectedComponent),
    ...components.filter(c => c.name !== selectedComponent),
  ];

  // function refreshPage() { 
  //   window.location.reload(true);
  // }
  return (
    <Modal show={show}   onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="popup_select_text">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body > 
        
        {sortedComponents.map(c => (
          <div key={c.name} className={selectedComponent === c.name ? 'active' : ''}>
            <p>{c.component}</p>
            <button
              className='mainSelectbtn btn'
              onClick={() => handleSelect(c.name)}
            >
              Select
            </button>
          </div>
        ))}
      </Modal.Body>
    </Modal>
  );
};

function App() {
  const [selectedComponent, setSelectedComponent] = useState(localStorage.getItem('selectedComponent') || 'Header');
  const [selectedAboutComponent, setSelectedAboutComponent] = useState(localStorage.getItem('selectedAboutComponent') || 'AboutPage1');
  const [selectedTabComponent, setSelectedTabComponent] = useState(localStorage.getItem('selectedTabComponent') || 'TabBar1');
  const [selectedBannerComponent, setSelectedBannerComponent] = useState(localStorage.getItem('selectedBannerComponent') || 'Banner1');
  const [selectedTestimonialComponent, setSelectedTestimonialComponent] = useState(localStorage.getItem('selectedTestimonialComponent') || 'Testimonial1');
  const [selectedSubscriptionComponent, setSelectedSubscriptionComponent] = useState(localStorage.getItem('selectedSubscriptionComponent') || 'Subscription1');
  // const [selectedContactComponent, setSelectedContactComponent] = useState(localStorage.getItem('selectedContactComponent') || 'Contact1');
  const [selectedFooterComponent, setSelectedFooterComponent] = useState(localStorage.getItem('selectedFooterComponent') || 'Footer1');

  const [showHeadersModal, setShowHeadersModal] = useState(false);
  const [showAboutPagesModal, setShowAboutPagesModal] = useState(false);
  const [showTabPageModel, setShowTabPageModel] = useState(false);
  const [showBannerPageModel, setShowBannerPageModel] = useState(false);
  const [showTestimonialPageModel, setShowTestimonialPageModel] = useState(false);
  const [showSubscriptionPageModel, setShowSubscriptionPageModel] = useState(false);
  // const [showContactPageModel, setShowContactPageModel] = useState(false);
  const [showFooterPageModel, setShowFooterPageModel] = useState(false);

  // Local Storage Effect
  useEffect(() => {
    localStorage.setItem('selectedComponent', selectedComponent);
    localStorage.setItem('selectedAboutComponent', selectedAboutComponent);
    localStorage.setItem('selectedTabComponent', selectedTabComponent);
    localStorage.setItem('selectedBannerComponent', selectedBannerComponent);
    localStorage.setItem('selectedTestimonialComponent', selectedTestimonialComponent);
    localStorage.setItem('selectedSubscriptionComponent', selectedSubscriptionComponent);
    // localStorage.setItem('selectedContactComponent', selectedContactComponent);
    localStorage.setItem('selectedFooterComponent', selectedFooterComponent);
   
  }, [selectedComponent, selectedAboutComponent, selectedTabComponent, selectedBannerComponent, selectedTestimonialComponent, selectedSubscriptionComponent,  selectedFooterComponent]);     
  // selectedContactComponent

  const handleComponentSelect = (componentName) => {
    setSelectedComponent(componentName);
    setShowHeadersModal(false);
  };

  const handleAboutComponentSelect = (componentName) => {
    setSelectedAboutComponent(componentName);
    setShowAboutPagesModal(false);
  };

  const handleTabComponentSelect = (componentName) => {
    setSelectedTabComponent(componentName);
    setShowTabPageModel(false);
  };

  const handleBannerComponentSelect = (componentName) => {
    setSelectedBannerComponent(componentName);
    setShowBannerPageModel(false);
  };


  const handleTestimonialComponentSelect = (componentName) => {
    setSelectedTestimonialComponent(componentName);
    setShowTestimonialPageModel(false)
  }

  const handleSubscriptionComponentSelect = (componentName) => {
    setSelectedSubscriptionComponent(componentName);
    setShowSubscriptionPageModel(false);
  };

  // const handleContactComponentSelect = (componentName) => {
  //   setSelectedContactComponent(componentName);
  //   setShowContactPageModel(false);
  // };

  const handleFooterComponentSelect = (componentName) => {
    setSelectedFooterComponent(componentName);
    setShowFooterPageModel(false);
  };


 

  // All Component Arrays 
  const headerComponents = [
    { name: 'Header', component: <Header handleSelect={() => handleComponentSelect('Header')} /> },
    { name: 'Header2', component: <Header2 handleSelect={() => handleComponentSelect('Header2')} /> },
    { name: 'Header3', component: <Header3 handleSelect={() => handleComponentSelect('Header3')} /> },
    { name: 'Header4', component: <Header4 handleSelect={() => handleComponentSelect('Header4')} /> },
    { name: 'Header5', component: <Header5 handleSelect={() => handleComponentSelect('Header5')} /> },
  ];

  const aboutPageComponents = [
    { name: 'AboutPage1', component: <AboutPage1 handleSelect={() => handleAboutComponentSelect('AboutPage1')} /> },
    { name: 'AboutPage2', component: <AboutPage2 handleSelect={() => handleAboutComponentSelect('AboutPage2')} /> },
    { name: 'AboutPage3', component: <AboutPage3 handleSelect={() => handleAboutComponentSelect('AboutPage3')} /> },
    { name: 'AboutPage4', component: <AboutPage4 handleSelect={() => handleAboutComponentSelect('AboutPage4')} /> },
    { name: 'AboutPage5', component: <AboutPage5 handleSelect={() => handleAboutComponentSelect('AboutPage5')} /> },
  ];

  const tabPageComponents = [
    { name: 'TabBar1', component: <TabBar1 handleSelect={() => handleTabComponentSelect('TabBar1')} /> },
    { name: 'TabBar2', component: <TabBar2 handleSelect={() => handleTabComponentSelect('TabBar2')} /> },
    { name: 'TabBar3', component: <TabBar3 handleSelect={() => handleTabComponentSelect('TabBar3')} /> },
    { name: 'TabBar4', component: <TabBar4 handleSelect={() => handleTabComponentSelect('TabBar4')} /> },
    { name: 'TabBar5', component: <TabBar5 handleSelect={() => handleTabComponentSelect('TabBar5')} /> },
  ];

  const bannerComponents = [
    { name: 'Banner1', component: <Banner1 handleSelect={() => handleBannerComponentSelect('Banner1')} /> },
    { name: 'Banner2', component: <Banner2 handleSelect={() => handleBannerComponentSelect('Banner2')} /> },
    { name: 'Banner3', component: <Banner3 handleSelect={() => handleBannerComponentSelect('Banner3')} /> },
    { name: 'Banner4', component: <Banner4 handleSelect={() => handleBannerComponentSelect('Banner4')} /> },
    { name: 'Banner5', component: <Banner5 handleSelect={() => handleBannerComponentSelect('Banner5')} /> },
  ];



const TestimonialComponents = [
  { name: 'Testimonial1', component: <Testimonial1 handleSelect={() => handleTestimonialComponentSelect('Testimonial1')} /> },
  { name: 'Testimonial2', component: <Testimonial2 handleSelect={() => handleTestimonialComponentSelect('Testimonial2')} /> },
  { name: 'Testimonial3', component: <Testimonial3 handleSelect={() => handleTestimonialComponentSelect('Testimonial3')} /> },
  { name: 'Testimonial4', component: <Testimonial4 handleSelect={() => handleTestimonialComponentSelect('Testimonial4')} /> },
  { name: 'Testimonial5', component: <Testimonial5 handleSelect={() => handleTestimonialComponentSelect('Testimonial5')} /> },
];




  const subscriptionComponents = [
    { name: 'Subscription1', component: <Subscription1 handleSelect={() => handleSubscriptionComponentSelect('Subscription1')} /> },
    { name: 'Subscription2', component: <Subscription2 handleSelect={() => handleSubscriptionComponentSelect('Subscription2')} /> },
    { name: 'Subscription3', component: <Subscription3 handleSelect={() => handleSubscriptionComponentSelect('Subscription3')} /> },
    { name: 'Subscription4', component: <Subscription4 handleSelect={() => handleSubscriptionComponentSelect('Subscription4')} /> },
    { name: 'Subscription5', component: <Subscription5 handleSelect={() => handleSubscriptionComponentSelect('Subscription5')} /> },
  ];

  // const contactComponents = [
  //   { name: 'Contact1', component: <Contact1 handleSelect={() => handleContactComponentSelect('Contact1')} /> },
  //   { name: 'Contact2', component: <Contact2 handleSelect={() => handleContactComponentSelect('Contact2')} /> },
  //   { name: 'Contact3', component: <Contact3 handleSelect={() => handleContactComponentSelect('Contact3')} /> },
  //   { name: 'Contact4', component: <Contact4 handleSelect={() => handleContactComponentSelect('Contact4')} /> },
  //   { name: 'Contact5', component: <Contact5 handleSelect={() => handleContactComponentSelect('Contact5')} /> },
  // ];

  const footerComponents = [
    { name: 'Footer1', component: <Footer1 handleSelect={() => handleFooterComponentSelect('Footer1')} /> },
    { name: 'Footer2', component: <Footer2 handleSelect={() => handleFooterComponentSelect('Footer2')} /> },
    { name: 'Footer3', component: <Footer3 handleSelect={() => handleFooterComponentSelect('Footer3')} /> },
    { name: 'Footer4', component: <Footer4 handleSelect={() => handleFooterComponentSelect('Footer4')} /> },
    { name: 'Footer5', component: <Footer5 handleSelect={() => handleFooterComponentSelect('Footer5')} /> },
  ];

  return (
    <div>
      <Button className='mainShowbutton btn_i btn btn-primary' onClick={() => setShowHeadersModal(true)}>Choose </Button>
      {selectedComponent === 'Header' && <Header />}
      {selectedComponent === 'Header2' && <Header2 />}
      {selectedComponent === 'Header3' && <Header3 />}
      {selectedComponent === 'Header4' && <Header4 />}
      {selectedComponent === 'Header5' && <Header5 />}

      <Button className='mainShowbutton btn_i btn btn-primary' onClick={() => setShowAboutPagesModal(true)}>Choose </Button>
      {selectedAboutComponent === 'AboutPage1' && <AboutPage1 />}
      {selectedAboutComponent === 'AboutPage2' && <AboutPage2 />}
      {selectedAboutComponent === 'AboutPage3' && <AboutPage3 />}
      {selectedAboutComponent === 'AboutPage4' && <AboutPage4 />}
      {selectedAboutComponent === 'AboutPage5' && <AboutPage5 />}

     
      <Button className='mainShowbutton  btn_i btn btn-primary' onClick={() => setShowBannerPageModel(true)}>Choose </Button>
      {selectedBannerComponent === 'Banner1' && <Banner1 />}
      {selectedBannerComponent === 'Banner2' && <Banner2 />}
      {selectedBannerComponent === 'Banner3' && <Banner3 />}
      {selectedBannerComponent === 'Banner4' && <Banner4 />}
      {selectedBannerComponent === 'Banner5' && <Banner5 />}


      <Button className=' mainShowbutton  btn_i btn btn-primary' onClick={() => setShowTabPageModel(true)}>Choose </Button>
      {selectedTabComponent === 'TabBar1' && <TabBar1 />}
      {selectedTabComponent === 'TabBar2' && <TabBar2 />}
      {selectedTabComponent === 'TabBar3' && <TabBar3 />}
      {selectedTabComponent === 'TabBar4' && <TabBar4 />}
      {selectedTabComponent === 'TabBar5' && <TabBar5 />}




      <Button className='mainShowbutton  btn_i btn btn-primary' onClick={() => setShowTestimonialPageModel(true)}>Choose </Button>
      {selectedTestimonialComponent === 'Testimonial1' && <Testimonial1 />}
      {selectedTestimonialComponent === 'Testimonial2' && <Testimonial2 />}
      {selectedTestimonialComponent === 'Testimonial3' && <Testimonial3 />}
      {selectedTestimonialComponent === 'Testimonial4' && <Testimonial4 />}
      {selectedTestimonialComponent === 'Testimonial5' && <Testimonial5 />}
 

      <Button className='mainShowbutton  btn_i btn btn-primary' onClick={() => setShowSubscriptionPageModel(true)}>Choose</Button>
      {selectedSubscriptionComponent === 'Subscription1' && <Subscription1 />}
      {selectedSubscriptionComponent === 'Subscription2' && <Subscription2 />}
      {selectedSubscriptionComponent === 'Subscription3' && <Subscription3 />}
      {selectedSubscriptionComponent === 'Subscription4' && <Subscription4 />}
      {selectedSubscriptionComponent === 'Subscription5' && <Subscription5 />}

      {/* <Button className='mainShowbutton  btn_i btn btn-primary' onClick={() => setShowContactPageModel(true)}>Choose</Button>
      {selectedContactComponent === 'Contact1' && <Contact1 />}
      {selectedContactComponent === 'Contact2' && <Contact2 />}
      {selectedContactComponent === 'Contact3' && <Contact3 />}
      {selectedContactComponent === 'Contact4' && <Contact4 />}
      {selectedContactComponent === 'Contact5' && <Contact5 />} */}

      <Button className='mainShowbutton  btn_i btn btn-primary' onClick={() => setShowFooterPageModel(true)}>Choose</Button>
      {selectedFooterComponent === 'Footer1' && <Footer1 />}
      {selectedFooterComponent === 'Footer2' && <Footer2 />}
      {selectedFooterComponent === 'Footer3' && <Footer3 />}
      {selectedFooterComponent === 'Footer4' && <Footer4 />}
      {selectedFooterComponent === 'Footer5' && <Footer5 />}

      {/* Modals for component selection */}
      <SelectionModal
        show={showHeadersModal}
        handleClose={() => setShowHeadersModal(false)}
        title="Select a Header"
        components={headerComponents}
        handleSelect={handleComponentSelect}
        selectedComponent={selectedComponent}
      />

      <SelectionModal
        show={showAboutPagesModal}
        handleClose={() => setShowAboutPagesModal(false)}
        title="Select an About Page"
        components={aboutPageComponents}
        handleSelect={handleAboutComponentSelect}
        selectedComponent={selectedAboutComponent}
      />

      <SelectionModal
        show={showTabPageModel}
        handleClose={() => setShowTabPageModel(false)}
        title="Select a Tab"
        components={tabPageComponents}
        handleSelect={handleTabComponentSelect}
        selectedComponent={selectedTabComponent}
      />

      <SelectionModal
        show={showBannerPageModel}
        handleClose={() => setShowBannerPageModel(false)}
        title="Select a Banner"
        components={bannerComponents}
        handleSelect={handleBannerComponentSelect}
        selectedComponent={selectedBannerComponent}
      />


      <SelectionModal
        show={showTestimonialPageModel}
        handleClose={() => setShowTestimonialPageModel(false)}
        title="Select a Testimonial"
        components={TestimonialComponents} // corrected
        handleSelect={handleTestimonialComponentSelect}
        selectedComponent={selectedTestimonialComponent}
      />


      <SelectionModal
        show={showSubscriptionPageModel}
        handleClose={() => setShowSubscriptionPageModel(false)}
        title="Select a Subscription"
        components={subscriptionComponents}
        handleSelect={handleSubscriptionComponentSelect}
        selectedComponent={selectedSubscriptionComponent}
      />

      {/* <SelectionModal
        show={showContactPageModel}
        handleClose={() => setShowContactPageModel(false)}
        title="Select a Contact"
        components={contactComponents}
        handleSelect={handleContactComponentSelect}
        selectedComponent={selectedContactComponent}
      /> */}

      <SelectionModal
        show={showFooterPageModel}
        handleClose={() => setShowFooterPageModel(false)}
        title="Select a Footer"
        components={footerComponents}
        handleSelect={handleFooterComponentSelect}
        selectedComponent={selectedFooterComponent}
      />
    </div>
  );
}

export default App;
