import React from 'react';
import PropTypes from 'prop-types';

function TabBar2({ 
  SubTitle, 
  TitleMain, 
  titleText1, 
  titleText2, 
  titleText3, 
  titleText4, 
  text1, 
  text2, 
  text3, 
  text4,
  handleSelect
}) {
  return (
    <section className='service_main_page2'>
       <button className='rightseltedbtn btn_i' onClick={handleSelect}>Choose 2</button>
      <div className='container'>
        <div className='MainServiceTitle'>
          <h5>{SubTitle}</h5>
          <h1>{TitleMain}</h1>
        </div>
        <div className='row'>
          <div className='col-lg-3'>
            <div className='servicePage'>
              <div className="arrow-up"></div>
              <div className='serviceTitle'>
                <h3>{titleText1}</h3>
              </div>
              <div className='servicetext_prg'>
                <p>{text1}</p>
              </div>
            </div>
          </div>

          <div className='col-lg-3'>
            <div className='servicePage'>
              <div className="arrow-right"></div>
              <div className='serviceTitle'>
                <h3>{titleText2}</h3>
              </div>
              <div className='servicetext_prg'>
                <p>{text2}</p>
              </div>
            </div>
          </div>

          <div className='col-lg-3'>
            <div className='servicePage'>
              <div className="arrow-down"></div>
              <div className='serviceTitle'>
                <h3>{titleText3}</h3>
              </div>
              <div className='servicetext_prg'>
                <p>{text3}</p>
              </div>
            </div>
          </div>

          <div className='col-lg-3'>
            <div className='servicePage'>
              <div className="arrow-left"></div>
              <div className='serviceTitle'>
                <h3>{titleText4}</h3>
              </div>
              <div className='servicetext_prg'>
                <p>{text4}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

TabBar2.defaultProps = {
  SubTitle: 'CAREER COACHING.',
  TitleMain: 'Custom fit services to help you get more Career Happiness',
  titleText1: 'CAREER CHANGE BOOTCAMP',
  titleText2: 'SIGNATURE COACHING PROGRAM',
  titleText3: 'INTERVIEW AND NEGOTIATION COACHING',
  titleText4: 'SEE ALL OUR COACHING PROGRAMS',
  text1: 'World-leading coaching program using our research-based framework. Career change guaranteed. Woohoos and cheering included.',
  text2: 'Custom-tailored coaching for professionals and executives, 100% adapted to you. With a cherry on top.',
  text3: 'You will find all the other types of coaching that we do including Interview & Negotiation coaching here.',
  text4: 'Just need help with interview prep, negotiating a salary, or have just landed a new role? Find all the coaching services we offer.',
};

TabBar2.propTypes = {
  SubTitle: PropTypes.string,
  TitleMain: PropTypes.string,
  titleText1: PropTypes.string,
  titleText2: PropTypes.string,
  titleText3: PropTypes.string,
  titleText4: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  text4: PropTypes.string,
};

export default TabBar2;
