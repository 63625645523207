import React from 'react';
import PropTypes from 'prop-types';

function Subscription1({ title1,  subTitle }) {  // Destructure props here
  return (
    <div>
      <section id="Subscrition2_main">
        <div className="container no-padding hidden">
          <div className="row">
            <div className="col-lg-12 align-items-center">
              <div className="Subscrition2_mainText">
                <div className="Subscrition1_title">
                  <h2>{title1}</h2>
                </div>
                <div className="Subscrition1_subTitle mb20">
                  <h4>{subTitle}</h4>
                </div>
                <div className="forms_main">
              <form action="#">
                <div className="main_groups Subscrition1 ">
                  <div className="group_fields">
                    <label htmlFor="fname">
                      <span className="visually-hidden">First name:</span>
                    </label>
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      placeholder="Your Name"
                    />
                  </div>
                  <div className="group_fields">
                    <label htmlFor="Yphone">
                      <span className="visually-hidden">Your Phone</span>
                    </label>
                    <input
                      type="phone"
                      id="phone"
                      name="phone"
                      placeholder="Your Phone"
                    />
                  </div>
                  <div className="group_fields">
                    <label htmlFor="Youremail">
                      <span className="visually-hidden">Your Email</span>
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Your Email"
                    />
                  </div>
                </div>
                
               
                <div className="main_groups">
                  <input
                    type="submit"
                    defaultValue="Submit"
                    className="submit_bttn btn_i"
                  />
                </div>
              </form>
            </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

Subscription1.defaultProps = {
  title1: 'THE MIDWEEK TWEAK ',

  subTitle: 'Want to get a quick and pertinent weekly email from me? Let s be friends.',
};

Subscription1.propTypes = {
  title1: PropTypes.string,

  subTitle: PropTypes.string,
};

export default Subscription1;
