import React from 'react';
import { Button, Navbar as BootstrapNavbar, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../assets/image/Braxweb_Airy-font-Logo-01.png';

const Header2 = ({ handleSelect }) => {
  return (
    <div className='HeaerTwo mt10'>
      <BootstrapNavbar expand="lg" className="n">
      <div className="container">
        <BootstrapNavbar.Brand href="#">
          <img src={logo} alt="Logo" className="navbar-logo" />
        </BootstrapNavbar.Brand>
        <BootstrapNavbar.Toggle aria-controls="navbarNav" />
        <BootstrapNavbar.Collapse id="navbarNav">
          <Nav className="ms-auto">
            <Nav.Link href="#">Home</Nav.Link>
            <Nav.Link href="#">About-us</Nav.Link>
            <Nav.Link href="#">Clients</Nav.Link>
            <Nav.Link href="#">Contact</Nav.Link>
            </Nav>
          <div className="sign-up">
                  <a href="#" className="Second_btn_sign-up btn_i">Schedule a Call </a>
                </div> 
        </BootstrapNavbar.Collapse>
      </div>
    </BootstrapNavbar>

    </div>
  );
};

export default Header2;
