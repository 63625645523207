import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import PropTypes from 'prop-types';
import img1 from '../assets/image/team-5.jpg';
import img2 from '../assets/image/team-4.jpg';
import img3 from '../assets/image/team-3.jpg';
import img4 from '../assets/image/team-2.jpg';
import img5 from '../assets/image/team-1.jpg';
import img6 from '../assets/image/team-3.jpg';

function Testimonial5({ title, subtitle }) {
    const testimonials = [
        {
            photo: img1,
            name: "Anup Kumar",
            position: "Software Developer",
            location: "India",
            content: '“Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus magni tempore provident? Quaerat, dicta saepe praesentium eaque nobis corrupti aut, quibusdam provident consequatur.”',
            stars: 4
        },
        {
            photo: img2,
            name: "Aman Kumar",
            position: "Software Developer",
            location: "India",
            content: '“Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus magni tempore provident? Quaerat, dicta saepe praesentium eaque nobis corrupti aut, quibusdam provident consequatur.”',
            stars: 4
        },
        {
            photo: img3,
            name: "Sahil Kumar",
            position: "Software Developer",
            location: "India",
            content: '“Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus magni tempore provident? Quaerat, dicta saepe praesentium eaque nobis corrupti aut, quibusdam provident consequatur.”',
            stars: 4
        },
        {
            photo: img4,
            name: "Ravi Singh",
            position: "Software Engineer",
            location: "India",
            content: '“Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus magni tempore provident? Quaerat, dicta saepe praesentium eaque nobis corrupti aut, quibusdam provident consequatur.”',
            stars: 4
        },
        {
            photo: img5,
            name: "Kiran Verma",
            position: "Project Manager",
            location: "India",
            content: '“Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus magni tempore provident? Quaerat, dicta saepe praesentium eaque nobis corrupti aut, quibusdam provident consequatur.”',
            stars: 5
        },
        {
            photo: img6,
            name: "Rakesh Yadav",
            position: "Data Scientist",
            location: "India",
            content: '“Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus magni tempore provident? Quaerat, dicta saepe praesentium eaque nobis corrupti aut, quibusdam provident consequatur.”',
            stars: 4
        },
    ];

    const [activeIndex, setActiveIndex] = useState(0);
    const [itemsPerSlide, setItemsPerSlide] = useState(3); 

    useEffect(() => {
        const updateItemsPerSlide = () => {
            if (window.innerWidth < 576) {
                setItemsPerSlide(1); 
            } else if (window.innerWidth < 768) {
                setItemsPerSlide(2); 
            } else {
                setItemsPerSlide(3); 
            }
        };

        updateItemsPerSlide(); 
        window.addEventListener('resize', updateItemsPerSlide); 

        return () => window.removeEventListener('resize', updateItemsPerSlide); 
    }, []);

    const handleSelect = (selectedIndex) => {
        setActiveIndex(selectedIndex);
    };

  
    const renderStars = (numStars) => {
        const fullStar = "★";
        const emptyStar = "☆";
        return (
            <div className="stars-rating">
                {fullStar.repeat(numStars) + emptyStar.repeat(5 - numStars)}
            </div>
        );
    };

    return (
        <section className="testimonial-section animate-up pdb50">
            <Container>
                <Row className="justify-content-center align-items-center position-relative">
                    <div className="testimonial-title text-center">
                        <h3>{title}</h3>
                        <p>{subtitle}</p>
                    </div>
                    <Col lg={12} className="position-relative">
                        <div className="testimonial-content-card">
                            <Carousel
                                activeIndex={activeIndex}
                                onSelect={handleSelect}
                                controls={false}    
                                indicators={true}  
                                interval={1000}
                                autoPlay={true}    
                            >
                                {testimonials.map((_, i, arr) => 
                                    i  % itemsPerSlide  === 0 && (
                                        <Carousel.Item key={i} interval={null}>
                                            <Row>
                                                {arr.slice(i, i + itemsPerSlide).map((testimonial, index) => (
                                                    <Col className='allSlidewith' key={index}>
                                                        <div className="testimonial-item testFiveMain">
                                                            <img
                                                                src={testimonial.photo}
                                                                alt={testimonial.name}
                                                                className="testimonial-photo"
                                                            />
                                                            <div className="testimonial_quote">
                                                                <p>{testimonial.name}</p>
                                                                <h5>{testimonial.position}, {testimonial.location}</h5>
                                                                <div className="testimonial_author5">
                                                                    <h5>{testimonial.content}</h5>
                                                                </div>
                                                       
                                                                {renderStars(testimonial.stars)}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Carousel.Item>
                                    )
                                )}
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

Testimonial5.defaultProps = {
    title: 'Sparking Leadership Success.',
    subtitle: 'Our revolutionary leadership coaching and training programs have helped hundreds of leaders.',
};

Testimonial5.propTypes = {
    title: PropTypes.string,
};

export default Testimonial5;
