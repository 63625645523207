// import React from 'react'

//  function Testimonial5() {
//   return (
//     <div>
//       <h1>Testimonial5</h1>
//     </div>
//   )
// }

// export default Testimonial5;

import React, { useState } from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import PropTypes from 'prop-types';
import img1 from '../assets/image/team-5.jpg';
import img2 from '../assets/image/team-4.jpg';
import img3 from '../assets/image/team-3.jpg';


// import img4 from '../assets/image/team-2.jpg';
// import img5 from '../assets/image/team-1.jpg';
// import img6 from '../assets/image/team-3.jpg';


function Testimonial5({ title, titleside, subtitleside }) {
    const testimonials = [
        {
            photo: img1,
            name: "Anup Kumar",
            position: "Software Developer",
            location: "India",
            content: '“This was definitely the best coaching and support I ever got from any therapy or program I participated before (and believe me, I did quite a few). I feel stronger and equipped with all the tools and knowledge I need, not only to keep myself binge free, but also to start loving myself more than before.”',
            stars:5
        },
        
        {
            photo: img2,
            name: "Aman Kumar",
            position: "Software Developer",
            location: "India",
            content: '“This was definitely the best coaching and support I ever got from any therapy or program I participated before (and believe me, I did quite a few). I feel stronger and equipped with all the tools and knowledge I need, not only to keep myself binge free, but also to start loving myself more than before”',
            stars:5
        },

        {
            photo: img3,
            name: "Sahil Kumar",
            position: "Software Developer",
            location: "India",
            content: '“This was definitely the best coaching and support I ever got from any therapy or program I participated before (and believe me, I did quite a few). I feel stronger and equipped with all the tools and knowledge I need, not only to keep myself binge free, but also to start loving myself more than before.”',
            stars:4
        },
    
    ];

    const [activeIndex, setActiveIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setActiveIndex(selectedIndex);
    };


    const renderStars = (numStars) => {
        const fullStar = "★";
        const emptyStar = "☆";
        return (
            <div className="stars-rating">
                {fullStar.repeat(numStars) + emptyStar.repeat(5 - numStars)}
            </div>
        );
    };

    return (
        <section className="testimonialTwo animate-up pdb50">
            <Container>
                <Row className="justify-content-center align-items-center position-relative">
                    <div className="testimonialTwo_title  text-center">
                        <h2>{title}</h2>
                    </div>
                    <Col lg={6} className="position-relative">
                    <div className='sideTitle'>
                      <h2>{titleside}</h2>
                      <h6>{subtitleside}</h6>
                    </div>


                    </Col>


                    <Col lg={6} className="position-relative">
                        <div className="testimonial_content_card">
                            <Carousel
                             className='tesimonial_boder2' 
                                activeIndex={activeIndex}
                                onSelect={handleSelect}
                                controls={false}    
                                indicators={true}  
                                interval={1000}   
                                loop={false} 
                               
                            >
                               
                                {testimonials.map((_, i, arr) => 
                                    i % 1 === 0 && (
                                        <Carousel.Item key={i} className='testimonial2boder'>
                                            <Row>
                                                {arr.slice(i, i + 1).map((testimonial, index) => (
                                                    <Col lg={12} key={index}>
                                                       <div className="testimonialInfo ">
                                                        <div className="testimonial_item mainAfterDesign">
                                                         <div className="testIcon">
                                                        
                                                        
                                                         </div>
                                                       
                                                            <div className="testimonial_quote">
                                                            <h3 className="testimonial_containt">
                                                                {testimonial.content}, 
                                                                </h3>
                                                            <div className='testiFlexText'>
                                                            <div className='testiImg'>
                                                            <img
                                                                src={testimonial.photo}
                                                                alt={testimonial.name}
                                                                className="tesiroundImg "
                                                            />

                                                            </div>
                                                                <div className='tesiInfo'>
                                                                <p>{testimonial.name}, </p>
                                                               {/* <p>{testimonial.position}, </p>
                                                                <p>{testimonial.location},</p> */}
                                                                
                                                               <strong>{renderStars(testimonial.stars)}</strong> 


                                                                </div>
                                                              
                                                            </div>
                                                            </div>
                                                            
                                                        </div>
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Carousel.Item>
                                    )
                                )}
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

Testimonial5.defaultProps = {
    title: 'TESTIMONIALS',
    titleside: "What People are saying",
    subtitleside: "Learning how to stop binge eating doesn't need. to be hard, scary, or stressful. Scroll through to read success stories from my clients",
};

Testimonial5.propTypes = {
    title: PropTypes.string,
};

export default Testimonial5;
