

import React from 'react';
import SubImg5 from '../assets/image/subscription3.png';
import PropTypes from 'prop-types';

function Subscription3({ title, titleside, subTitle}) { // Destructure props here
  return (
    <div>
      <section id="get_touch_m pdb50">
        <div className="container no-padding hidden">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="subsCribeMain3"
                
              >
                <div className="Subscritipn_title3 ">
                  <h2>{title}</h2>
                  <p>{(subTitle)}</p>
                </div>
                <div className="Subscritipn_subTitle2 ">
                  <h4>{titleside}</h4>
                </div>
                <div className="Subscritipn_input">
                  <form action="#">
                    <div className="Subscritipn_main_groups3">
                      <div className="group_fields subButton3">
                        <label htmlFor="Youremail">
                          <span className="visually-hidden">Your Email</span>
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Email Address"
                        />
                        <a
                        type="submit"
                        defaultValue="Submit"
                        className="SubsSubmit3 btn_i "
                      >Sign in </a>
                      </div>
                   
                
                   
                    </div>
                  </form>
                </div>
            
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center">
             

              
              <div className="subImg"  style={{ backgroundImage: `url(${SubImg5})` }}>
                 <div className='subback3'>
                  <h4>Adam Bornstein, Born Fitness Founder</h4>

                 </div>
                </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

Subscription3.defaultProps = {
  title: 'We Simplify Health',
  subTitle: '(So You Can Enjoy Life)',
  titleside: 'Get Exclusive Health Tips We Only Share With Email Subscribers.',
}



Subscription3.propTypes = {
  title: PropTypes.string,
  titleside: PropTypes.string,
  
};

export default Subscription3;

